export let fade = {
  show: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeOut",
      duration: 0.5,
      staggerChildren: 0.4,
      delayChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    x: -20,
  },
};

export const projectsContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      staggerChildren: 0.3,
      delayChildren: 0.4,
    },
  },
};

export const personalProjectsContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      staggerChildren: 0.3,
      delayChildren: 1,
    },
  },
};

export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      staggerChildren: 2,
      delayChildren: 0.5,
    },
  },
};

export let project = {
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.3,
      duration: 0.5,
    },
  },
  hidden: {
    y: 20,
    opacity: 0,
  },
};
