import Nav from "../components/navigation";
import FooterLinks from "../components/footer-links";
import { fade, project, projectsContainer } from "../components/animations";
import "../styles/App.scss";

import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Container,
  Typography,
  Box,
  Link as MuiLink,
} from "@mui/material";
import { motion } from "framer-motion";
import { Links, Urls } from "../constants.js";

function Home() {
  return (
    <>
      <Container maxWidth="lg" className="home">
        <Nav />
        <Box
          className="title"
          sx={{ pt: "30vh", pb: "50vh" }}
          component={motion.div}
          variants={fade}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <Typography variant="h1">i'm marla defensor.</Typography>
          <Typography variant="h2">
            front-end software engineer. web designer.
          </Typography>
        </Box>

        <Grid container>
          <Box
            className="about-me"
            sx={{ pb: "50vh" }}
            component={motion.div}
            variants={fade}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.5 }}
          >
            <Typography variant="h3">hello there!</Typography>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" id="about-blurb">
                My name is Marla Defensor and I’m a Chicago-based front-end
                software engineer, web designer, and avid home baker. I'm a
                poodle-mix dog lover, tv-binge watcher, and most especially an
                impulsive nick nack buyer.
              </Typography>
            </Grid>
            <Box className="link-box">
              <Link to={Links.ABOUT} className="links">
                learn more about me here
              </Link>
            </Box>
          </Box>
        </Grid>

        <Box
          className="projects"
          sx={{ pb: "30vh" }}
          component={motion.div}
          variants={projectsContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.6 }}
        >
          <Typography variant="h3" component={motion.h3} variants={fade}>
            featured work.
          </Typography>
          <Grid container justifyContent="left" alignItems="center" spacing={1}>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.HIMS_WL} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  Hims & Hers - Weight Loss
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.HIMS_ED} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  Hims - ED
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.HIMS_HAIR} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  Hims & Hers - Hair Loss
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.WEDDING} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  JP and Nadine's Wedding
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.HERMAN_MILLER} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  Herman Miller Sentiment
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={12} md={6} component={motion.div} variants={project}>
              <MuiLink href={Urls.SPORTS_CAMPS} rel="noopener" target="_blank">
                <Button
                  variant="outlined"
                  className="project-button"
                  tabIndex="-1"
                >
                  MSU Sports Camp
                </Button>
              </MuiLink>
            </Grid>
          </Grid>
          <Box className="link-box" component={motion.div} variants={fade}>
            <Link to={Links.PROJECTS} className="links">
              check out more of my projects here
            </Link>
          </Box>
        </Box>
        <footer>
          <FooterLinks />
        </footer>
      </Container>
    </>
  );
}

export default Home;
