import "../styles/App.scss";
import Nav from "../components/navigation.js";
import FooterLinks from "../components/footer-links.js";
import { Link } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Box,
  Link as MuiLink,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { motion } from "framer-motion";
import { fade } from "../components/animations";
import { Links, Urls } from "../constants.js";

const links = [
  { name: "GitHub", url: Urls.GITHUB, icon: GitHubIcon },
  { name: "Instagram", url: Urls.INSTAGRAM, icon: InstagramIcon },
  { name: "LinkedIn", url: Urls.LINKEDIN, icon: LinkedInIcon },
];

function About() {
  const languages = [
    "TypeScript",
    "JavaScript",
    "HTML",
    "CSS",
    "Java",
    "jQuery",
    "Sass",
    "Python",
    "C++",
    "PHP",
    "MySQL",
  ];
  const frameworks = [
    "React",
    "Redux",
    "Bootstrap",
    "Foundation",
    "MaterialUI",
    "Carbon",
  ];
  const tools = [
    "GitHub",
    "AWS",
    "Figma",
    "Gulp",
    "Photoshop",
    "InDesign",
    "Drupal",
    "Kubernetes",
    "OpenShift Cloud Platform",
  ];
  const industry = [
    "A/B Testing",
    "Agile",
    "Responsive Web Design",
    "Web Accessibility",
    "REST APIs",
    "CI/CD",
  ];
  return (
    <>
      <Container maxWidth="lg">
        <Nav />
        <Grid
          container
          xs={12}
          md={6}
          sx={{ pt: "20vh", pb: "30vh" }}
          component={motion.div}
          variants={fade}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <Typography variant="h3">about me</Typography>
          <Typography variant="body1">
            Ever since I was a kid, I’ve always been a sucker for design and
            tech. Never would I have thought I could be doing both. Whether it
            be a quick website edit or an intensive redesign, building and
            designing websites are things I will never grow tired of.
          </Typography>
        </Grid>
        <Box
          className="education"
          sx={{ pb: "30vh" }}
          component={motion.div}
          variants={fade}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.95 }}
        >
          <Typography variant="h4">education</Typography>
          <Box sx={{ pb: "10px" }}>
            <Typography variant="body1" className="school">
              Michigan State University
            </Typography>
            <Typography variant="body1" className="school-info">
              AUG 2016 - MAY 2020 | East Lansing, Michigan
            </Typography>
            <Typography variant="body1" className="school-info">
              Bachelors, Computer Science with a Business Cognate
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className="school">
              Udacity
            </Typography>
            <Typography variant="body1" className="school-info">
              MAY 2019 - AUG 2019 | Online Program
            </Typography>
            <Typography variant="body1" className="school-info">
              Nanodegree, Front-End Web Developer
            </Typography>
          </Box>
        </Box>
        <Box
          className="interests"
          sx={{ pb: "30vh" }}
          component={motion.div}
          variants={fade}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.8 }}
        >
          <Typography variant="h4">interests</Typography>
          <Typography variant="body1">
            baking, ceramics, painting, plant collecting, and taking pictures of
            my dog, Winston.
          </Typography>
          <Typography variant="body1" sx={{ pt: "10px" }}>
            wanna know more? find me on these platforms:
          </Typography>
          <Grid
            direction="row"
            container
            item
            xs={6}
            sm={2.5}
            md={2}
            justifyContent="space-between"
            sx={{ pt: "10px" }}
          >
            {links.map((link) => (
              <Grid item>
                <MuiLink
                  href={link.url}
                  title={link.name + " - Marla Defensor"}
                  tabIndex="0"
                  rel="noopener"
                  target="_blank"
                >
                  <motion.div
                    className="icon-links"
                    whileHover={{ backgroundColor: "rgba(124, 108, 99, 0.1)" }}
                  >
                    <link.icon fontSize="large" sx={{ color: "#7C6C63" }} />
                  </motion.div>
                </MuiLink>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          className="skillset"
          sx={{ pb: "30vh" }}
          component={motion.div}
          variants={fade}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.3 }}
        >
          <Typography variant="h4">my stack</Typography>
          <Grid container spacing={2} sx={{ pb: "2vh" }}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h5">languages</Typography>
              {languages.map((language) => (
                <Typography variant="body1">{language}</Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h5">tools/softwares</Typography>
              {tools.map((tools) => (
                <Typography variant="body1">{tools}</Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h5">libraries</Typography>
              {frameworks.map((frameworks) => (
                <Typography variant="body1">{frameworks}</Typography>
              ))}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h5">industry knowledge</Typography>
              {industry.map((industry) => (
                <Typography variant="body1">{industry}</Typography>
              ))}
            </Grid>
          </Grid>
          <Link to={Links.PROJECTS} className="links">
            check out my projects here
          </Link>
        </Box>

        <FooterLinks />
      </Container>
    </>
  );
}

export default About;
