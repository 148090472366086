import { NavLink } from "react-router-dom";
import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Stack,
  Link as MuiLink
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Links } from "../constants.js";

const pages = {
  "about me" : Links.ABOUT,
  "projects" : Links.PROJECTS,
};

export default function Nav() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

    return (
      <>
      
      <nav>
        <AppBar position="static">
            <Toolbar disableGutters>
              <Typography
                className="nav-home"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                <NavLink to="/" className="links logo">
                  md.
                </NavLink>
              </Typography>

              <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu bar"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon className="menu-icon"/>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {Object.keys(pages).map((key) => (
                    <NavLink to={pages[key]}>
                      <MenuItem key={key} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{key}</Typography>
                      </MenuItem>
                    </NavLink>
                  ))}
                  <MuiLink href="Resume_MarlaMaeDefensor.pdf" className="links" rel="noopener" target="_blank">
                    <MenuItem key="resume" onClick={handleCloseNavMenu} className="resume">
                      <Typography textAlign="center">resume</Typography>
                    </MenuItem>
                  </MuiLink>
                  <a href="mailto:mdefensor97@gmail.com">
                    <MenuItem key="contact me" onClick={handleCloseNavMenu} className="contact-me">
                      <Typography textAlign="center">contact</Typography>
                    </MenuItem>
                  </a>
                </Menu>
              </Box>
              <Stack spacing={1} direction="row" sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                {Object.keys(pages).map((key) => (
                  <NavLink to={pages[key]}>
                    <Button
                      key={key}
                      onClick={handleCloseNavMenu}
                      className="nav-link-button"
                      sx={{ display: 'block' }}
                      tabIndex="-1"
                    >
                      {key}
                    </Button>
                  </NavLink>
                ))}
                <MuiLink href="Resume_MarlaMaeDefensor.pdf" rel="noopener" target="_blank">
                  <Button
                    key="resume"
                    onClick={handleCloseNavMenu}
                    className="nav-link-button resume"
                    sx={{ display: 'block' }}
                    tabIndex="-1"
                  >
                    resume
                  </Button>
                </MuiLink>
                <a href="mailto:mdefensor97@gmail.com">
                    <Button
                      key="contact me"
                      onClick={handleCloseNavMenu}
                      className="nav-link-button contact-me"
                      sx={{ display: 'block' }}
                      tabIndex="-1"
                    >
                      contact
                    </Button>
                </a>
              </Stack>
            </Toolbar>
        </AppBar>
      </nav>
      </>
    );
  }