export const Urls = {
  HIMS_WL: "https://www.forhers.com/c/wm",
  HIMS_ED: "https://www.hims.com/c/e",
  HIMS_HAIR: "https://www.hims.com/c/hl",
  WEDDING: "https://defensorbautistawedding.netlify.app",
  HERMAN_MILLER: "https://capstone.cse.msu.edu/2020-01/projects/herman-miller/",
  SPORTS_CAMPS: "https://sportscamps.msu.edu/",
  GITHUB: "https://github.com/marladefensor",
  INSTAGRAM: "https://www.instagram.com/marladefensor/",
  LINKEDIN: "https://www.linkedin.com/in/marladefensor/",
  LIVE_ON: "https://liveon.msu.edu/apartments",
  RESTAURANT: "https://marladefensor-restaurantapp.netlify.app/",
  THREE_JS: "https://marladefensor-cse472project2.netlify.app/",
};

export const Links = {
  HOME: "/",
  ABOUT: "/about-me",
  PROJECTS: "/projects",
};
