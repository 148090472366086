import '../../styles/App.scss';
import Nav from '../../components/navigation.js';
import FooterLinks from '../../components/footer-links.js';
import {
  Container,
  Typography,
  Box,
  Link as MuiLink
} from '@mui/material';
import initial1 from '../../assets/initial-1.jpg';
import initial2 from '../../assets/initial-2.jpg';
import landingMockups from '../../assets/liveon-landing-mockups.png';
import secondaryDrafting from '../../assets/liveon-secondary-drafting.png';
import secondaryMockups from '../../assets/liveon-secondary-mockups.png';
import final from '../../assets/liveon-final.png';
import { Urls } from '../../constants.js';

function Liveon() {
    return (
      <>
        <Container maxWidth="lg" className="case-studies">
        <Nav />
        <Box
            sx={{ pb: '5vh' }}
        >
            <Typography variant="h3">
                <MuiLink href={Urls.LIVE_ON} className="links case-study-title" target="_blank" rel="noopener">LiveOn Apartments</MuiLink>
            </Typography>
            <Box className="case-study-info">
                <Typography variant="body1"><b>Client:</b> MSU Resident Education and Housing Services (REHS)</Typography>
                <Typography variant="body1"><b>Category:</b> Front-End Development, Web Design</Typography>
                <Typography variant="body1"><b>Date:</b> July 2019 - October 2019</Typography>
                <Typography variant="body1"><b>Location:</b> East Lansing, Michigan</Typography>
            </Box>
        </Box>
        <Box
            sx={{pb: '5vh', width: { xs: '100%', md: '600px'}, float: { md: 'right' } }}
        >
            <Typography variant="h4">introduction</Typography>
            <Typography variant="body1">As the demand for apartment living at Michigan State University has increased, REHS has decided to advertise MSU apartments as a separate entity from Michigan State’s on-campus residence halls.</Typography>
        </Box>
        <figure>
            <img src={initial1} alt="initial page setup for LiveOn 1" className="casestudy-img initial-img" />
            <Typography variant="subtitle1"><b>figure 1:</b> initial secondary page for MSU Apartment complexes</Typography>
        </figure>
        <figure>
            <img src={initial2} alt="initial page setup for LiveOn 2" className="casestudy-img initial-img" />
            <Typography variant="subtitle1"><b>figure 2:</b> Apartments section within the Halls and Apartments section on LiveOn</Typography>
        </figure>
        <Box
            className="section"
            sx={{ width: { xs: '100%', md: '600px'} }}
        >
            <Typography variant="h4">objectives</Typography>
            <ul className="case-study-list">
            <Typography variant="body1" component="li">MSU Apartments should be comparable to off-campus apartment options but with the added benefits like being on campus</Typography>
            <Typography variant="body1" component="li">The design has to follow a similar theme to other LiveOn pages</Typography>
            <Typography variant="body1" component="li">MSU Apartments pages have to be easier to access</Typography>
            </ul>
        </Box>
        <Box
            className="section"
            sx={{ width: { xs: '100%', md: '600px'}, float: { md: 'right' } }}
        >
            <Typography variant="h4">challenges</Typography>
            <Typography variant="h5">web design challenges</Typography>
            <Typography variant="body1">One of the biggest challenges I had in designing the Apartments section was ensuring the page was not only informative but well designed.
            </Typography>
        </Box>
        <figure>
            <img src={landingMockups} alt="mockups for LiveOn Apartments landing page" className="casestudy-img" />
            <Typography variant="subtitle1"><b>figure 3:</b> landing page mockups - middle image to the right was the selected mockup</Typography>
        </figure>
        <Box
            className="section"
            sx={{ width: { xs: '100%', md: '600px'}, float: { md: 'right' } }}
        >
            <Typography variant="body1">To resolve this, the content for the landing page was broken up into three main sections: the introduction of LiveOn Apartments, some brief details about the area, and the different apartment communities students can live in.
            </Typography>
        </Box>
        <figure>
            <img src={secondaryDrafting} alt="mockups for LiveOn Apartments secondary page" className="casestudy-img" />
            <Typography variant="subtitle1"><b>figure 4:</b> drafting process for secondary page</Typography>
        </figure>
        <Box
        className="section"
        sx={{ width: { xs: '100%', md: '600px'} }}
        >
            <Typography variant="body1">
            Another big challenge I had was making sure the secondary pages had the same theme as the landing page but modified to accommodate for all the content.
            This was resolved in two ways. First, the content was sectioned off by displaying the selling points of LiveOn Apartments first, then all resident information towards the bottom of the page. Secondly, instead of making a vertical box checkered pattern for the content, the boxes were turned horizontal to encourage less text within each of the content boxes.
            </Typography>
        </Box>
        <figure>
            <img src={secondaryMockups} alt="mockups for LiveOn Apartments secondary page" className="casestudy-img"/>
            <Typography variant="subtitle1"><b>figure 5:</b> secondary page mocks for MSU Apartment complexes - the one on far left was selected</Typography>
        </figure>
        <Box
            className="section"
            sx={{ width: { xs: '100%', md: '800px'} }}
        >
            <Box sx={{ pb: '1vh' }}>
            <Typography variant="h5">front-end development challenges</Typography>
            <Typography variant="body1">Though coding this website was pretty straightforward, an issue I had in the front-end development portion was using a CMS environment I wasn’t familiar with, Drupal. As I was adding onto a website that has already been established, I had to ensure that I wasn’t recreating content styles and blocks which were already in the Drupal backend server.</Typography>
            </Box>
            <Box>
            <Typography variant="body1">Though reading through the repository appeared quite daunting at first, the senior Front-End developer at MSU Residential Hospitality Services, Eric, gave me a great introduction to Drupal and really guided me through the process of figuring out which files I should be editing and. From then on, it was simply a matter of reading documentation to ensure my practices aligned with those who wrote the LiveOn code before me.</Typography>
            </Box>
        </Box>
        <figure>
            <img src={final} alt="final mockups for LiveOn Apartments" className="casestudy-img" />
            <Typography variant="subtitle1"><b>figure 6:</b> final mockups for landing and secondary pages</Typography>
        </figure>
        <Box
            className="section"
            sx={{ width: { xs: '100%', md: '800px'} }}
        >
            <Typography variant="h4">conclusion</Typography>
            <Typography sx={{ pb: '1vh' }} variant="body1">With the addition of the Apartments section to the LiveOn website, this will better advertise on-campus apartments as its own entity instead of being a subsection of MSU residence halls. This also greatly benefits current residents living in the on-campus apartments as they can go directly to the Apartment section to look for information regarding their living situation, rather than clicking through multiple sections to get their destination. Having all these changes made to the LiveOn website has made MSU Apartments a great contender against other off-campus apartments.</Typography>
            <MuiLink href={Urls.LIVE_ON} className="links" rel="noopener" target="_blank">check out LiveOn Apartments here</MuiLink>
        </Box>
        <FooterLinks />
        </Container>
      </>
    );
  }

  export default Liveon;