import React from "react";
import "../../styles/App.scss";
import Nav from "../../components/navigation.js";
import FooterLinks from "../../components/footer-links.js";
import { Container, Typography, Box } from "@mui/material";
import initial from "../../assets/memorygame-initial.png";

function Memorygame() {
  return (
    <>
      <Container maxWidth="lg" className="case-studies">
        <Nav />
        <Box>
          <Typography variant="h3" className="case-study-title">
            Memory Game
          </Typography>
          <Box className="case-study-info">
            <Typography variant="body1">
              <b>Client:</b> Udacity, Front-End Development Project
            </Typography>
            <Typography variant="body1">
              <b>Category:</b> Front-End Development, Web Design
            </Typography>
            <Typography variant="body1">
              <b>Date:</b> June 2019 - July 2019
            </Typography>
            <Typography variant="body1">
              <b>Location:</b> East Lansing, Michigan
            </Typography>
          </Box>
          <Box
            className="section"
            sx={{ width: { xs: "100%", md: "600px" }, float: { md: "right" } }}
          >
            <Typography variant="h4">introduction</Typography>
            <Typography variant="body1">
              This project is one of the core projects required to finish the
              Front-End Web Development Nanodegree on Udacity. Though the
              premise of the project was to convert a static HTML page to a
              dynamic one using JavaScript, I wanted to take the project further
              by redesigning it as well.
            </Typography>
          </Box>
          <figure>
            <img
              src={initial}
              alt="initial design for memory game"
              className="casestudy-img"
            />
            <Typography variant="subtitle1">
              <b>figure 1:</b> inital design of memory game given for the
              project
            </Typography>
          </figure>
          <Box className="section" sx={{ width: { xs: "100%", md: "600px" } }}>
            <Typography variant="h4">objectives</Typography>
            <ul className="case-study-list">
              <Typography variant="body1" component="li">
                The redesign should be playable on different viewports
              </Typography>
              <Typography variant="body1" component="li">
                Make the matching game playable by adding card flipping
                functionality and matching card interactions
              </Typography>
              <Typography variant="body1" component="li">
                Have a modal appear with the player’s results once they complete
                the game
              </Typography>
            </ul>
          </Box>
          <Box className="section" sx={{ width: { xs: "100%", md: "600px" } }}>
            <Typography variant="h4">challenges</Typography>
            <Typography variant="h5">web design challenges</Typography>
            <Typography variant="body1">
              The biggest issue I had with this game was resizing the game for
              different viewports. Because there is a set amount of boxes per
              row, I couldn’t use relative widths and heights for the boxes. At
              different viewports, I would get unexpected box sizes. To resolve
              this issue, I used pixels to determine the sizes for the boxes.
              These set pixels were then changed for each viewport so the game
              would be clearly visible to the user on whatever screen they were
              using.
            </Typography>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "600px" } }}>
            <Typography variant="h5">
              front-end development challenges
            </Typography>
            <Typography variant="body1">
              Implementing the onClick events were quite simple, but what became
              difficult was the card flipping functionality. Though the flipping
              of cards was not necessary for the project requirements, this
              allowed the user to have a more dynamic experience with the game.
              In order to implement the flipping animation, I did some research
              on CSS animations, specifically 3D rotations. Rather than just
              changing the icon and color of the matched cards, the game would
              now have a card flipping to its other side to display the icon.
            </Typography>
          </Box>
          {/* <figure>
              <div className="container" alignText="center">
                  <div className={isActive ? "flip" : null} id="card" onClick={handleFlip}>
                  <div className="front"><Typography variant="body1">press me for card flip</Typography></div>
                  <div className="back"><Typography variant="body1">press me for card flip</Typography></div>
                  </div>
              </div>
            </figure> */}
          <Box className="section" sx={{ width: { xs: "100%", md: "600px" } }}>
            <Typography variant="body1">
              Another issue I had with the game was creating a modal that would
              display the game results when a player won. I wanted to make sure
              that once the user reaches the modal, they wouldn’t be able to
              interact with the game in the background. This was a quick fix.
              After reading up solutions online, I made sure to center the
              modal, gray out the background, and index it to the front so
              nothing can be clickable behind the modal.
            </Typography>
          </Box>
          <Box
            sx={{ pb: "5vh", width: { xs: "100%", md: "600px" } }}
            className="section"
          >
            <Typography variant="h4">conclusion</Typography>
            <Typography variant="body1" sx={{ pb: "2vh" }}>
              By the time I completed this project, I was able to play the game
              in multiple viewports, have card flipping functionality, and have
              a modal display when the game completes. Overall, this Udacity
              project has enabled me to get a better understanding of JavaScript
              for front-end development. Thus, I am much more confident in
              making interactive websites with my knowledge of JavaScript.
            </Typography>
            <Typography variant="body1">
              check out the memory game below
            </Typography>
          </Box>
          <Box>
            <iframe
              src="https://marladefensor-memorygame.netlify.app/"
              width="100%"
              height="650"
              scrolling="no"
              title="memory game"
            ></iframe>
          </Box>
        </Box>
        <FooterLinks />
      </Container>
    </>
  );
}

export default Memorygame;
