import "../styles/App.scss";
import Nav from "../components/navigation";
import FooterLinks from "../components/footer-links";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  Link as MuiLink,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  fade,
  project,
  projectsContainer,
  container,
  personalProjectsContainer,
} from "../components/animations";
import { Urls } from "../constants.js";

function Projects() {
  return (
    <>
      <Container maxWidth="lg">
        <Nav />
        <Box
          component={motion.div}
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <Typography sx={{ pt: "10vh" }} variant="h3" component={motion.h3}>
            projects
          </Typography>
          <Box
            className="projects"
            sx={{ pb: "10vh" }}
            component={motion.div}
            variants={projectsContainer}
          >
            <Typography variant="h4" component={motion.h4} variants={fade}>
              professional work.
            </Typography>
            <Grid
              container
              justifyContent="left"
              alignItems="center"
              spacing={1}
            >
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.HIMS_WL} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    Hims & Hers - Weight Loss
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.HIMS_ED} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    Hims - ED
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.HIMS_HAIR} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    Hims & Hers - Hair Loss
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.WEDDING} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    JP and Nadine's Wedding
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink
                  href={Urls.HERMAN_MILLER}
                  rel="noopener"
                  target="_blank"
                >
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    Herman Miller Sentiment
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink
                  href={Urls.SPORTS_CAMPS}
                  rel="noopener"
                  target="_blank"
                >
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    MSU Sports Camp
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <Link to="live-on">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    MSU LiveOn Apartments
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            className="projects"
            sx={{ pb: "20vh" }}
            component={motion.div}
            variants={personalProjectsContainer}
          >
            <Typography variant="h4" component={motion.h4} variants={fade}>
              personal work.
            </Typography>
            <Grid
              container
              justifyContent="left"
              alignItems="center"
              spacing={1}
            >
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.RESTAURANT} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    restaurant reviews
                  </Button>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <Link to="memory-game">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    memory game
                  </Button>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                component={motion.div}
                variants={project}
              >
                <MuiLink href={Urls.THREE_JS} rel="noopener" target="_blank">
                  <Button
                    variant="outlined"
                    className="project-button"
                    tabIndex="-1"
                  >
                    threejs + webgl practice
                  </Button>
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <FooterLinks />
      </Container>
    </>
  );
}

export default Projects;
