import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#9F4C60',
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '5.25rem',
      fontWeight: 900,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 500,

    },
    h3: {
      fontSize: '2.5rem',
      fontWeight: 600,
      paddingBottom: 10,
      color: '#9F4C60'
    },
    h4: {
      fontSize: '1.9rem',
      fontWeight: 600,
      paddingBottom: 10,
    },
    h5: {
      fontSize: '1.23rem',
      fontWeight: 500,
      paddingTop: 5,
      paddingBottom: 2
    },
    subtitle1: {
      fontSize: '0.9rem'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'none',
          boxShadow: 'none',
          border: 'none'
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#FCFBF8',
          // background: '#9F4C60',
          boxShadow: 'none',
          // boxShadow: '1px 5px 7px -2px rgba(159,76,96,0.16)',
          border: 'solid 1px rgba(159, 76, 96, 0.5)'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: "rgba(159, 76, 96, 0.04)",
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: "rgba(159, 76, 96, 0.04)",
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: "rgba(159, 76, 96, 0.04)",
          }
        }
      }
    },
  },
  // components: {
  //   // Name of the component
  //   MuiButton: {
  //     styleOverrides: {
  //       // Name of the slot
  //       root: {
  //         // Some CSS
  //         fontSize: '1rem',
  //       },
  //     },
  //   },
  // },
});

theme = responsiveFontSizes(theme);

export default theme;