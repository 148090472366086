import './styles/App.scss';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Projects from './pages/Projects.js';
import Liveon from './pages/projects/Liveon.js';
import Memorygame from './pages/projects/Memorygame.js';
import ScrollToTop from './components/scroll-to-top.js';
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App blobs">
      <header className="App-header">
        <ScrollToTop>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="about-me" element={<About />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/live-on" element={<Liveon />} />
            <Route path="projects/memory-game" element={<Memorygame />} />
          </Routes>
        </ScrollToTop>
      </header>
    </div>
    </ThemeProvider>
  );
}

export default App;
