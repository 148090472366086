import "../styles/App.scss";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, Typography, Link, Box } from "@mui/material";
import { motion } from "framer-motion";
import { Urls } from "../constants.js";

const links = [
  { name: "GitHub", url: Urls.GITHUB, icon: GitHubIcon },
  { name: "Instagram", url: Urls.INSTAGRAM, icon: InstagramIcon },
  { name: "LinkedIn", url: Urls.LINKEDIN, icon: LinkedInIcon },
];

export default function FooterLinks() {
  return (
    <>
      <Box
        className="footer-details"
        sx={{ pb: 2, display: { xs: "none", md: "block" } }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            direction="row"
            container
            item
            md={2}
            justifyContent="space-between"
          >
            {links.map((link) => (
              <Grid item>
                <Link
                  href={link.url}
                  title={link.name + " - Marla Defensor"}
                  rel="noopener"
                  target="_blank"
                  tabIndex="0"
                >
                  <motion.div
                    class="icon-links"
                    whileHover={{ backgroundColor: "rgba(124, 108, 99, 0.1)" }}
                  >
                    <link.icon fontSize="large" sx={{ color: "#7C6C63" }} />
                  </motion.div>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid md={10}>
            <Typography id="copyright" variant="subtitle1">
              &#169; Marla Defensor {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="footer-details"
        sx={{ pb: 4, display: { xs: "block", md: "none" } }}
      >
        <Grid
          direction="row"
          container
          item
          xs={6}
          justifyContent="space-between"
          // sx={{ pb: 1 }}
        >
          {links.map((link) => (
            <Grid item>
              <Link
                href={link.url}
                title={link.name + " - Marla Defensor"}
                rel="noopener"
                target="_blank"
              >
                <motion.div
                  class="icon-links"
                  whileHover={{ backgroundColor: "rgba(124, 108, 99, 0.1)" }}
                >
                  <link.icon fontSize="large" sx={{ color: "#7C6C63" }} />
                </motion.div>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Typography variant="subtitle1">
          &#169; Marla Defensor {new Date().getFullYear()}
        </Typography>
      </Box>
    </>
  );
}
